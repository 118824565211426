import React, {Fragment, FC} from 'react';
import {Link} from 'gatsby';
import {Translate} from '../../components/translations';
import {facebook, youtube, twitter, linkedin, instagram} from '../../images/svgIcons';
import chromeIcon from '../../images/hexomatic-chrome-extension.png';
import firefoxIcon from '../../images/firefox-add-on.png';
import logo from '../../images/hexomatic-logo.svg';
import './styles.scss';

const footerMenuItems = [
  {
    name: 'PRODUCT',
    subItems: [
      {name: 'Why Hexomatic', linkTo: '/about/'},
      {name: 'Automations', linkTo: '/automations/'},
      {name: 'Workflow templates', linkTo: '/ready-made-workflow-library/'},
      {name: 'Pricing', linkTo: '/pricing/'},
      {name: 'Tools', linkTo: '/tools/'},
      {name: 'FAQ', linkTo: '/faq/'},
    ],
  },
  {
    name: 'COMPANY',
    subItems: [
      // {name: 'Affiliate program', linkTo: '/affiliate/'},
      {name: 'Software integrations', linkTo: '/software-integrations/'},
      {name: 'Contact us', linkTo: '/contact/'},
    ],
  },
  {
    name: 'RESOURCES',
    subItems: [
      {name: 'Academy', linkTo: 'https://hexomatic.com/academy/', target: '_blank', ariaLabel: 'hexomatic blog'},
      {
        name: 'Tutorials',
        linkTo: 'https://hexomatic.com/academy/tutorials/',
        target: '_blank',
        ariaLabel: 'hexomatic blog',
      },
      {name: 'Roadmap', linkTo: '/roadmap/'},
      {
        name: 'Changelog',
        linkTo: 'https://hexomatic.com/academy/changelog/',
        target: '_blank',
        ariaLabel: 'hexomatic blog changelog',
      },
    ],
  },
  {
    name: 'LEGAL',
    subItems: [
      {name: 'Terms of Service', linkTo: '/terms-of-use/'},
      {name: 'Privacy Policy', linkTo: '/privacy-policy/'},
      {name: 'Cookie Policy', linkTo: '/cookie-policy/'},
    ],
  },
];

const Footer: FC<{pricingPage: boolean}> = ({pricingPage}) => {
  return (
    <footer className="footer-container" id="footer">
      <div className="footer_chat">
        <div className="container">
          <div className="any-questions-text">
            <Translate name="FOOTER_UPPER_TEXT_LEFT" />{' '}
            <a
              //@ts-ignore
              href=""
              onClick={e => {
                e.preventDefault();
                //@ts-ignore
                window.Intercom('show');
              }}
            >
              Chat now
            </a>{' '}
            or Email to{' '}
            <a href="mailto:support@hexomatic.com" aria-label={'mail'} target="_blank">
              support@hexomatic.com
            </a>
          </div>
        </div>
      </div>
      {!pricingPage ? (
        <div className="footer_top p-0">
          <div className="container">
            <div className="row m-0 pt-5 pb-3">
              <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                <div className='mb-4'>
                  <div className="widget-wrap mt-0">
                    <Link to="/">
                      <img src={logo} alt="Hexomatic" width={140} height={35} />
                    </Link>
                    <p className="hexomatic-description">
                      Hexomatic is your no-code growth hacking sidekick, enabling universal access to the internet as
                      your own data source, leveraging the most sophisticated AI services to automate and delegate
                      time-consuming tasks.
                    </p>
                  </div>
                  <div className="footer-extensions">
                    <a
                      aria-label="Hexomatic Chrome extension"
                      href="https://chrome.google.com/webstore/detail/hexomatic/gaeglheibddedbjiehaeeacoldaiimkl"
                      target="_blank"
                      rel="nofollow noopener"
                      
                    >
                      <img
                        src={chromeIcon}
                        width="160px"
                        alt="Hexomatic Chrome extension"
                        className="m-1"
                      />
                    </a>
                    <a
                      aria-label="Hexomatic Fitefox add-on"
                      href="https://addons.mozilla.org/en-US/firefox/addon/hexomatic/"
                      target="_blank"
                      rel="nofollow noopener"
                      
                    >
                      <img src={firefoxIcon} width="160px" alt="Hexomatic Firefox add-on" className="m-1"/>
                    </a>
                  </div>
                </div>
              </div>
              {footerMenuItems.map(
                (item): JSX.Element => (
                  <Fragment key={item.name}>
                    <div className="col-lg-2 col-md-2 col-sm-6 footer-menu-item d-flex justify-content-center">
                      <div className="about-widget">
                        <div className="footer-sub-items ">
                          {item.name && item.name.length > 0 && <h3 className="footer-menu-item-title">{item.name}</h3>}
                          {item.subItems && (
                            <ul className="list-unstyled f_list">
                              {' '}
                              {item.subItems.map(
                                (subItem): JSX.Element => (
                                  <li key={subItem.name}>
                                    {subItem.linkTo && subItem.linkTo.indexOf('://') > -1 ? (
                                      <a aria-label={subItem.ariaLabel} href={subItem.linkTo} target={subItem.target}>
                                        {subItem.name}
                                      </a>
                                    ) : (
                                      <Link
                                        to={subItem.linkTo || ''}
                                        state={
                                          subItem.linkTo && subItem.linkTo === '/login'
                                            ? {goBack: true}
                                            : {goBack: false}
                                        }
                                      >
                                        {subItem.name}
                                      </Link>
                                    )}
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ),
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 text">
              &copy; {new Date().getFullYear()} Hexact, Inc. All rights reserved. 1250 E. Hallandale Beach Blvd., Unit 808,
              Hallandale Beach, Florida 33009
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 d-flex">
              <div className="d-flex social-icons-container">
                <a
                  aria-label="Facebook"
                  className="social-icon"
                  href="https://www.facebook.com/hexactinc/"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  {facebook}
                </a>
                <a
                  aria-label="Youtube"
                  className="social-icon"
                  href="https://www.youtube.com/c/HexactInc"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  {youtube}
                </a>
                <a
                  aria-label="LinkedIn"
                  className="social-icon"
                  href="https://www.linkedin.com/company/hexactinc"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  {linkedin}
                </a>
                <a
                  aria-label="Twitter"
                  className="social-icon"
                  href="https://twitter.com/hexactinc"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  {twitter}
                </a>
                <a
                  aria-label="Instagram"
                  className="social-icon"
                  href="https://www.instagram.com/hexacthq/"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  {instagram}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
